.btn {
  &.btn-gradient {
    @include border-radius($border-radius-large);
    padding: 8px;
    border-color: transparent;

    &.btn-default {
      color: $white;
      @include gradient-vertical(lighten($text-color, 7%), $text-color); }
    &.btn-primary {
      @include gradient-vertical(lighten($brand-primary, 7%), $brand-primary); }
    &.btn-info {
      @include gradient-vertical(lighten($brand-info, 7%), $brand-info); }
    &.btn-success {
      @include gradient-vertical(lighten($brand-success, 7%), $brand-success); }
    &.btn-warning {
      @include gradient-vertical(lighten($brand-warning, 7%), $brand-warning); }
    &.btn-danger {
      @include gradient-vertical(lighten($brand-danger, 7%), $brand-danger); }

    .icon-pdf {
      margin-left: 10px;
      width: 25px;
      height: auto; } } }
