// Responsive Helper
.dev-responsive {
  position: fixed;
  bottom: 0px;
  left: 10px;
  height: 35px;
  line-height: 35px;
  padding: 0px 10px;
  background-color: $black;
  color: $white;
  z-index: 2000; }


// Admin Gear
.admin-gear {
  display: none; }
.user-role-administrator,
.user-role-author {
  .admin-gear {
    display: block;
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 100;
    a, a:visited, a:focus, a:active {
      @include inline-block();
      background-color: $black;
      color: $white;
      padding: 10px; }
    a:hover {
      background-color: $brand-primary; } } }

// Style Page
.bs-component {
  position: relative;
  #source-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    font-weight: 700; } }
