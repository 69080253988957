#block-menu-menu-footer-menu {
  width: 410px;
  position: relative;
  top: 30px;
  padding: 17px 0px;
  padding-left: 55px;
  border-left: 1px solid #44788d;
  ul.nav {
    li {
      @include inline-block();
      width: 115px;
      a, a:visited, a:focus {
        color: #75c5d6;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 3px 0px;
        background: none; }
      a:hover {
        background: none;
        color: $white; } } } }
