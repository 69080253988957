#block-block-52 {
  padding-top: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0px 10%;
  margin-top: 25px;

  a.back-to-top {
    &, &:hover {
      display: block;
      text-decoration: none;
      i {
        font-size: $font-size-h4; } } } }
