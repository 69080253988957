.view-user-manuals {
  .views-row {
    margin-bottom: 35px;
    h4 {
      color: $brand-info;
      border-bottom: 1px solid $tealish;
      padding-bottom: 5px;
      margin-bottom: 15px; }

    figure {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        z-index: 1; }
      .overlay {
        @include transition(0.3s);
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.6);
        opacity: 0;
        .btn {
          @include inline-block();
          width: 180px;
          margin: 0 auto;
          left: 0;
          right: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          z-index: 3; } }
      &:hover {
        .overlay {
          opacity: 1; } } } } }
