#block-block-90 {
  ol {
    li {
      @media #{$lg} {
        &:nth-child(1) {
          @include graph-line-right();
          width: 430px;
          top: 185px;
          left: 15px; }
        &:nth-child(2) {
          @include graph-line-left();
          width: 430px;
          top: 141px;
          right: 60px; }
        &:nth-child(3) {
          @include graph-line-left();
          width: 400px;
          top: 304px;
          right: 26px; } } } } }
