#block-system-main-menu {
  .block-content {
    > ul {
      text-align: right;
      @media #{$lg} {
        text-align: center; }
      > li {
        @include inline-block();
        > a {
          &, &:visited, &:focus, &:active {
            @include round();
            @include inline-block();
            @include transition-none();
            font-size: $font-size-small;
            text-transform: uppercase;
            font-weight: bold;
            color: $brand-info;
            background: none;
            border: 1px solid transparent;
            padding: 10px 11px; }
          &:hover {
            color: $brand-info;
            border-color: #576a77; } }
        &.active,
        &.active-trail {
          a {
            &, &:visited, &:focus, &:active, &:hover {
              color: $brand-primary !important; }
            &:hover {
              border-color: $brand-primary !important; } } } } } } }

// White nav version
body.white-nav {
  #block-system-main-menu .block-content {
    > ul li {
      a {
        &, &:visited, &:focus, &:active {
          color: $white; }
        &:hover {
          border-color: $white; } } } } }


// Home Page
body.front {
  #block-system-main-menu .block-content > ul > li > a {
    &, &:visited, &:focus, &:active, &:hover {
      @include transition($transition-speed-normal);
      border-color: transparent; } } }
