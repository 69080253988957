@mixin graph-line-right() {
  background-image: url('../img/graph-line-right.png');
  background-position: top right;
  padding-right: 90px;
  padding-left: 0px; }
@mixin graph-line-left() {
  background-image: url('../img/graph-line-left.png');
  background-position: top left;
  padding-left: 90px;
  padding-right: 0px; }

.graph {
  img {
    @media #{$md} {
      margin-bottom: 25px;
      float: left;
      width: 460px; }
    @media #{$sm}, #{$xs} {
      margin-bottom: 25px;
      width: 100%;
      height: auto;
      float: none; } }
  ol {
    padding: 0px;
    margin: 0px;
    li {
      list-style: none;
      color: $gray-light;
      font-size: 18px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 82px 50px;
      padding-top: 11px;
      position: absolute;

      @media #{$md}, #{$sm}, #{$xs} {
        position: static;
        min-height: 82px;
        display: block;
        clear: both;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          @include graph-line-left();
          margin: 0 auto;
          width: 100%; } }
      @media #{$xs} {
        background: none !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: static;
        width: 100% !important;
        margin-bottom: 10px; } }
    @media #{$md} {
      padding-left: 15px;
      float: right;
      width: 50%; }
    @media #{$sm}, #{$xs} {
      width: 100%;
      float: none;
      padding-left: 0px; } } }
