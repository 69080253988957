.field-zoom {
  position: relative;
  @include inline-block;
  @include transition($transition-speed-base);
  border: 1px solid transparent;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  img {
    display: block;
    &:first-child {
      @include transition($transition-speed-base);
      opacity: 1; }
    &::selection {
      background-color: transparent; } }

  .help {
    @include transition($transition-speed-base);
    opacity: 1;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 3;
    color: $gray-light;
    font-size: $font-size-small;
    font-style: italic;
    font-weight: 300;
    .icon {
      position: relative;
      top: 2px; } }

  &.zoomed {
    border-color: #CCC;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    img {
      cursor: hand; }
    img:first-child {
      opacity: 0; }
    .help {
      opacity: 0; } } }
