#block-afb-2 {
  label,
  .ajax-progress-throbber {
    display: none !important; }

  .form-control {
    &::-webkit-input-placeholder {
      font-style: italic; } }

  select {
    width: 100%;
    border: 1px solid #ccc;
    color: #999999;
    font-weight: normal;
    text-transform: none;
    &.valid {
      background-size: 20px 20px; } } }
