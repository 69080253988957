.btn {
  &.btn-middle-icon {
    margin: 11px 0px;
    position: relative;
    padding: 0px 36px;
    line-height: 25px;
    span {
      background: $black;
      @include round();
      @include inline-block();
      text-align: center;
      margin: -13px 0px;
      width: 49px;
      height: 49px;
      vertical-align: middle;
      position: relative;
      top: -1px;
      &:after {
        content: " ";
        background-color: white;
        height: 26px;
        display: block;
        position: absolute;
        top: 11px;
        z-index: 0;
        left: 0px;
        right: 0px; }
      .fa {
        background-color: $white;
        width: 47px;
        height: 47px;
        @include round();
        @include inline-block();
        position: relative;
        top: 1px;
        &:before {
          position: relative;
          top: 16px;
          z-index: 1;
          color: white;
          background: $black;
          padding: 10px 12px;
          @include round(); } } }
    &:hover {
      span {
        &:after {
          display: none; }
        .fa:before {
          background: $white !important; } } }

    &.btn-default {
      span {
        background: $btn-default-border;
        .fa:before {
          background: $btn-default-border; } }
      &:hover {
        span .fa {
          background: $btn-default-border;
          &:before {
            color: $btn-default-border; } } } }
    &.btn-primary {
      span {
        background: $brand-primary;
        .fa:before {
          background: $brand-primary; } }
      &:hover {
        span .fa {
          background: $brand-primary;
          &:before {
            color: $brand-primary; } } } }
    &.btn-success {
      span {
        background: $brand-success;
        .fa:before {
          background: $brand-success; } }
      &:hover {
        span .fa {
          background: $brand-success;
          &:before {
            color: $brand-success; } } } }
    &.btn-info {
      span {
        background: $brand-info;
        .fa:before {
          background: $brand-info; } }
      &:hover {
        .fa {
          background: $brand-info;
          &:before {
            color: $brand-info; } } } }
    &.btn-warning {
      span {
        background: $brand-warning;
        .fa:before {
          background: $brand-warning; } }
      &:hover {
        .fa {
          background: $brand-warning;
          &:before {
            color: $brand-warning; } } } }
    &.btn-danger {
      span {
        background: $brand-danger;
        .fa:before {
          background: $brand-danger; } }
      &:hover {
        .fa {
          background: $brand-danger;
          &:before {
            color: $brand-danger; } } } } } }
