.view-photo-gallery {
  .view-content {
    padding: 10px;
    > a {
      vertical-align: bottom;
      opacity: 0; } } }

#lightcase-info {
  #lightcase-title {
    color: $white !important;
    @media #{$xs} {
      left: 0 !important;
      max-width: 100% !important;
      right: 0 !important;
      display: block !important;
      width: 100% !important;
      padding-right: 55px !important; } } }

#lightcase-info {
  p {
    font-size: 12px !important;
    color: rgba(255, 255, 255, 0.9) !important; }
  @media #{$xs} {
    padding: 5px !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: rgba(0, 0, 0, 0.5);
    p {
      font-size: 11px !important; } } }
