#block-block-57 {
  table {
    margin-top: 140px;
    @media  #{$md} {
      margin-top: 100px; }
    @media  #{$sm} {
      margin-top: 80px; }
    @media  #{$xs} {
      margin-top: 60px;
      h2 {
        font-size: $font-size-base; } } } }
