.page-node-2081 {
  .region-page-content {
    > .row {
      > section {
        @include make-md-column(6); }
      > aside {
        @include make-md-column(6);
        .block-title {
          margin-top: 22px !important; } } } }
  .node-contact_request-form {
    padding-right: 30px;
    @media #{$sm}, #{$xs} {
      padding-right: 0px; }
    label {
      display: none !important; }
    textarea {
      height: 138px; }
    .form-submit {
      @media #{$sm}, #{$xs} {
        display: block;
        width: 100%; } } } }
