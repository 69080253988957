.view-cycle-square-block {
  position: relative;
  &,
  .cycle-background {
    height: 620px;
    @media #{$xs}, #{$sm} {
      height: auto; } }

  .cycle-slide {
    width: 100%;
    h2, h3, h4 {
      margin-top: 0px; } }

  .cycle-background {
    blockquote {
      padding-top: 200px;
      padding-left: 15%;
      padding-right: 15%;
      @media #{$sm} {
        height: 520px;
        padding-top: 100px;
        margin-bottom: 0px; }
      @media #{$xs} {
        height: 620px;
        padding-top: 50px;
        padding-left: 10%;
        padding-right: 10%;
        margin-bottom: 0px; }
      //@media #{$sm}
      //  padding-top: 100px
      //@media #{$xs}
      //  padding-top: 50px
      p {
        color: $white; }
      .image {
        text-align: center;
        img {
          @include inline-block; } } }
    .custom-text {
      position: absolute;
      bottom: 55px;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      h4 {
        color: $white; }
      p {
        color: $blue-light; }
      @media #{$xs}, #{$sm} {
        position: static;
        padding-bottom: 30px;
        padding-left: $padding-base-horizontal;
        padding-right: $padding-base-horizontal; }
      @media #{$xs} {
        padding-top: 200px; }
      @media #{$sm} {
        padding-top: 440px; } } }

  &.view-cycle-full-page {
    .cycle-background {
      blockquote {
        padding-left: 20%;
        padding-right: 20%;
        @media #{$xs} {
          padding-top: 110px;
          padding-left: 30px;
          padding-right: 30px; } } } }

  .views-slideshow-controls-bottom {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    z-index: 300;
    text-align: center;
    .views-slideshow-pager-field-item {
      cursor: pointer;
      @include inline-block();
      margin: 0px 5px;
      color: rgba(255, 255, 255, 0.3);
      &:hover {
        color: rgba(255, 255, 255, 0.5); }
      &.cycle-pager-active {
        color: $brand-warning; } } } }
