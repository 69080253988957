.view-products {
  position: relative;
  left: -1px;
  width: calc(100% + 1px);
  @media #{$xs}, #{$sm} {
    position: static;
    width: 100%; }
  .view-content {
    position: relative;
    left: -15px;
    @media #{$xs}, #{$sm} {
      position: static; }
    .row {
      .col {
        padding: 0px;
        @media #{$xs} {
          border-bottom: 1px solid #CCC; }
        > .views-field {
          text-align: center;
          box-shadow: 1px 0 0 0 #CCC, 0 1px 0 0 #CCC, 1px 1px 0 0 #CCC, 1px 0 0 0 #CCC inset, 0 1px 0 0 #CCC inset;

          @media #{$xs} {
            padding-bottom: 30px;
            box-shadow: none; }

          .views-field-field-images-cropped {
            a, span, img {
              display: block; }
            a {
              padding: 30px; } }
          .views-field-title {
            color: $gray-light;
            padding: 0px 15px;
            height: 44px;
            h2 {
              font-size: 25px;
              margin-top: 50px; } }
          .views-field-button {
            padding: 15px 0px; } } }
      @media #{$xs} {
        &:first-child {
          .col:first-child {
            .views-field-title h2 {
              margin-top: 15px; } } }
        &:last-child {
          .col:last-child {
            border-bottom: 0px; } } } } } }
