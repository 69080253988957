.view-featured-products {
  margin-bottom: 30px;
  .product-side-left,
  .product-side-right {
    @include inline-block();
    width: 50%; }

  .product-side-left {
    > .views-field {
      background-color: #eeeeee;
      text-align: center;
      padding: 50px;

      @media #{$lg} {
        height: 100%; }
      @media #{$md} {
        height: 100%; }
      @media #{$sm} {
        height: 100%; }

      h2 {
        margin-top: 0px; }

      .views-field-title {
        display: none; }

      .views-field-field-images-cropped {
        a, span {
          display: block; }
        a {
          padding: 30px; } }

      .views-field-button-small {
        display: none; }
      .views-field-button-large {} } }


  .product-side-right {
    .views-row {
      text-align: center;
      box-shadow: 1px 0 0 0 #CCC, 0 1px 0 0 #CCC, 1px 1px 0 0 #CCC, 1px 0 0 0 #CCC inset, 0 1px 0 0 #CCC inset;
      float: left;
      width: 50%;
      height: 50%;
      > .views-field {
        padding: 15px 30px;
        .views-field-field-images-cropped {
          a, span, img {
            display: block; }
          a {
            padding-top: 15px; } }

        h2 {
          display: none; }

        .views-field-title {
          color: $gray-light; }
        .views-field-button-small {
          margin-top: 15px; }
        .views-field-button-large {
          display: none; } } } }

  // Slideshow (xs)
  &.view-display-id-block_1 {
    background-color: #eeeeee;
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 15px;
    .carousel {
      margin-bottom: 0px;
      h2 {
        padding: 0px 15px; }
      .views-field-button-small {
        display: none; }
      .views-field-field-images-cropped {
        a {
          display: block;
          padding: 30px;
          img {
            @include inline-block(); } } } } } }
