form#quote-product-customization-form {
  box-shadow: 0 0 0 0.5px rgba(50,50,93,0.17), 0 2px 5px 0 rgba(50,50,93,0.1), 0 1px 1.5px 0 rgba(0,0,0,0.07), 0 1px 2px 0 rgba(0,0,0,0.08), 0 0 0 0 transparent !important;
  padding: 15px;
  border-radius: $border-radius-base;
  margin-top: 2rem;

  select {
    text-transform: none; }


  .form-submit {
    margin-top: 1rem; }

  .cables-wrapper {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    > fieldset {
      margin: 0 15px;
      @media #{$xs} {
        margin: 0 5px; } } }

  fieldset {
    border: 0;
    box-shadow: none;
    legend {
      background: none;
      padding: 0;
      .panel-title {
        font-size: 12px;
        text-transform: uppercase;
        color: #888;
        font-weight: bold; } }
    .panel-body {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 0;
      > .form-item {
        margin-right: 5px;
        // &:last-child
        //   margin-right: 5px
        .control-label {
          display: block; } }
      .suffix {
        position: relative;
        top: 5px; } } } }
