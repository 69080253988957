$footer-height: 190px;

html {
  position: relative;
  min-height: 100%; }


#region-footer {
  background-color: #0a5573;
  position: absolute;
  left: 0;
  bottom: 0;
  height: $footer-height;
  width: 100%; }


.main-container {
  margin-bottom: $footer-height; }

/* Remove Fixed Footer on Mobile */
@media #{$xs}, #{$sm} {
  html, body {
    height: auto; }

  .main-container {
    margin-bottom: 0px; }

  #region-footer {
    position: static;
    height: auto;
    padding: 0px; } }
