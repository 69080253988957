ul {
  &.list-inline {
    &.list-divided {
      li {
        border-right: 1px solid #b8c0cc;
        padding-right: 20px;
        padding-left: 15px;
        &:first-child {
          padding-left: 5px; }
        &:last-child {
          padding-right: 0px;
          border: 0px; }
        @media #{$xs} {
          display: block;
          border: 0px;
          padding: 8px !important;
          text-align: center; } } } }

  &.list-styled {
    padding-left: 0px;
    li {
      padding-left: 1.2em;
      list-style: none;
      > div {
        display: inline; }
      &:before {
        @include inline-block();
        @include font-jdteck();
        content: "\63";
        font-size: $font-size-base;
        line-height: 30px;
        color: $brand-primary;
        width: 1.4em;
        margin-left: -1.2em;
        vertical-align: middle;
        position: relative;
        top: -1px; } } }

  &.list-checkmarks {
    padding-left: 0px;
    li {
      padding-left: 1.2em;
      list-style: none;
      font-size: $font-size-small;
      color: #888;
      > div {
        display: inline; }
      &:before {
        @include inline-block();
        @include font-jdteck();
        content: "\6f";
        font-size: $font-size-base;
        line-height: 30px;
        color: $brand-primary;
        width: 1.4em;
        margin-left: -1.2em;
        vertical-align: middle;
        position: relative;
        top: -1px; } } } }
