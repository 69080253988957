#block-block-47 {
  &.block {
    h1 {
      font-size: $font-size-h2; }
    img {
      margin-bottom: 10px; }
    p {
      margin-bottom: 0px; }
    hr {
      margin-top: 0px;
      margin-bottom: 50px; }

    @media #{$md} {
      img {
        margin-bottom: 5px; }
      hr {
        display: none; }
      p {
        margin-bottom: 10px; } }

    @media #{$sm} {
      padding-top: 25px;
      padding-bottom: 25px;
      background-position: right -250px bottom;
      .content-padded-right {
        padding-right: 0px; } }
    @media #{$xs} {
      text-align: center;
      padding-top: 25px;
      padding-bottom: 25px;
      .block-content-container {
        padding: 0px;
        img {
          width: 120px;
          height: auto;
          margin: 5px 5px; }
        hr {
          display: none; }
        .btn {
          margin-top: 170px; }
        .views-field-nothing {
          display: none; } } } } }
