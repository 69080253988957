.label {
  &.label-lg {
    @include inline-block();
    padding: 5px 30px;
    font-size: $font-size-h4;
    @media #{$xs} {
      padding-left: 15px;
      padding-right: 15px; } }
  &.label-xl {
    @include inline-block();
    padding: 13px 50px;
    font-size: $font-size-h2; }
  &.label-rounded {
    @include round(); }
  &.label-transparent {
    &.label-primary {
      background: transparentize($brand-primary, $label-transparent-multiplier); }
    &.label-info {
      background: transparentize($brand-info, $label-transparent-multiplier); }
    &.label-success {
      background: transparentize($brand-success, $label-transparent-multiplier); }
    &.label-warning {
      background: transparentize($brand-warning, $label-transparent-multiplier); }
    &.label-danger {
      background: transparentize($brand-danger, $label-transparent-multiplier); } } }
