.form-control {
  position: relative;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;

  &:focus {
    box-shadow: none;
    border-color: $brand-primary; }

  &.valid,
  &.error {
    padding-right: 50px; }

  &.valid {
    &, &:focus {
      border-color: $brand-success;
      background-image: url('../img/icon-validate-valid.png'); } }
  &.error {
    &, &:focus {
      border-color: $brand-danger;
      background-image: url('../img/icon-validate-error.png'); } }

  &.round {
    @include round(); } }

textarea.form-control {
  background-position: right 10px top 10px; }

form {
  .captcha {
    margin-bottom: 1.2rem; } }
