.panel-group {
  border: 1px solid $brand-info;
  .panel {
    &.panel-jdteck {
      border: 0px !important;
      margin: 0px;
      box-shadow: none;
      .panel-heading {
        padding: 0px;
        border-color: transparent !important;
        .panel-title {
          a {
            &, &:visited, &:focus, &:active {
              display: block;
              letter-spacing: 2px;
              font-size: 15px;
              font-weight: bold;
              color: $brand-info;
              text-decoration: none;
              height: 48px;
              line-height: 48px;
              padding: 0px 25px;
              border-bottom: 1px solid $brand-info;
              position: relative;
              @media #{$md} {
                padding: 0px 15px; } }
            &:hover {
              color: $brand-primary; }
            &:after {
              @include font-jdteck();
              content: "\67";
              position: absolute;
              right: 10px;
              font-size: $font-size-large;
              line-height: 48px; }
            &[aria-expanded=true] {
              color: $brand-primary;
              border-bottom-color: transparent;
              &:after {
                content: "\69"; } } } } }
      &.last {
        .panel-collapse {
          border-bottom: 0px !important; } }
      .panel-collapse {
        &[aria-expanded=true] {
          border-bottom: 1px solid $brand-info; }
        .panel-body {
          margin-top: 0px;
          padding: 0px;
          padding-bottom: 20px;
          border-color: transparent !important;
          ul {
            padding: 0px;
            margin: 0px;
            li {
              list-style: none;
              padding: 0px;
              margin: 0px;
              display: block;
              a {
                &, &:visited, &:focus, &:active {
                  display: block;
                  color: $brand-info;
                  display: block;
                  padding: 5px 25px;
                  @media #{$md} {
                    padding: 5px 15px; }
                  line-height: 22px;
                  letter-spacing: 1px; } }
              &.active a {
                color: $brand-primary;
                font-weight: bold; } } } } }
      &.last {
        .panel-heading .panel-title a {
          border-bottom-color: transparent; } } } } }
