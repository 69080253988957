.carousel {
  padding-bottom: 60px;
  margin-bottom: 20px;
  .carousel-indicators {
    bottom: 0px;
    li {
      &, &.active {
        @include size(15px, 15px);
        margin: 0px 5px; } } } }

.view,
.carousel {
  &.carousel-info,
  &.view-carousel-info {
    .carousel-indicators li {
      background-color: #cfdae2;
      border: none;
      &.active {
        background-color: $brand-info; } } }
  &.carousel-primary,
  &.view-carousel-primary {
    .carousel-indicators li {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      &.active {
        background-color: $brand-primary; } } }
  &.carousel-warning,
  &.view-carousel-warning {
    .carousel-indicators li {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      &.active {
        background-color: $brand-warning; } } }
  &.carousel-warning-white,
  &.view-carousel-warning-white {
    .carousel-indicators li {
      background-color: rgba(255, 255, 255, 0.4); } } }
