$horizontal-block-height-lg: 220px;
$horizontal-block-height-md: 200px;
$horizontal-block-height-sm: 200px;
$horizontal-block-height-xs: 200px;
$horizontal-block-transition-speed: 0.2s;


.horizontal-blocks {
  .horizontal-block {
    position: relative;
    width: 25%;
    float: left;
    outline: 1px solid #07212c;

    @media #{$xs} {
      width: 100%;
      float: none;
      outline: none;
      border-bottom: 1px solid #07212c; }

    &,
    > a.block-container,
    > a.block-container figure {
      height: $horizontal-block-height-lg;
      @media #{$md} {
        height: $horizontal-block-height-md; }
      @media #{$sm} {
        height: $horizontal-block-height-sm; }
      @media #{$xs} {
        height: $horizontal-block-height-xs; } }

    > a.block-container {
      position: relative;
      @include inline-block();
      &, figure {
        width: 100%;
        text-align: center; }
      figure {
        position: absolute;
        overflow: hidden;
        z-index: 0;
        img {
          @include inline-block();
          @include transition($horizontal-block-transition-speed);
          width: 100%;
          transform: scale(1.1);
          height: 400px;
          position: relative;
          top: -175px;
          @media #{$md} {
            height: 100%;
            position: static;
            top: 0; }
          @media #{$xs} {
            height: auto;
            transform: none; } } }
      .overlay {
        @include transition($horizontal-block-transition-speed);
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        @include gradient-vertical(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)); }

      .title {
        @include transition($horizontal-block-transition-speed);
        background: transparent url('../img/teal-line.png') top 50px center no-repeat;
        background-size: 1px 200px;
        position: absolute;
        z-index: 3;
        bottom: 0px;
        left: 0;
        right: 0;
        padding-bottom: 100px;
        text-align: center;
        @media #{$md} {
          padding-bottom: 80px; }
        @media #{$sm} {
          bottom: inherit;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          background: none; }

        span {
          @include transition($horizontal-block-transition-speed);
          @include inline-block();
          @include round();
          padding: 10px 30px;
          border: 1px solid transparent;
          letter-spacing: 1px;
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          color: $white;
          @media #{$md} {
            padding: 13px 20px;
            font-size: $font-size-base; }
          @media #{$sm} {
            padding: 0px;
            border: 0px;
            font-size: $font-size-small; } } }


      &:hover {
        figure img {
          transform: scale(1); }
        .overlay {
          opacity: 1; }
        .title {
          padding-bottom: 150px;
          @media #{$md} {
            padding-bottom: 130px; }
          @media #{$sm} {
            padding-bottom: 30px; }
          span {
            border-color: $teal; } } } } } }


#block-bean-solutions-horizontal-list {
  .horizontal-blocks {
    .horizontal-block {
      width: 20%;
      @media #{$xs} {
        width: 100%; } } } }
