.mce-tinymce {
  border: 2px solid $input-border !important;
  border-radius: $border-radius-base;

  .mce-menubar {
    display: none;
  }

  .mce-toolbar-grp {
    padding: 2px;
    .mce-btn {
      display: block;
      float: left;
    }
  }

  .mce-panel,
  .mce-btn {
    border: 1px solid transparent;
    background-color: white;
  }
  .mce-btn {
    &:hover, &:focus, &:active {
      border-radius: 2px;
      background: #fafafa;
      border-color: $input-border;
      color: #23282d;
      box-shadow: 0 1px 0 rgba(0, 0, 0, .08);
      outline: 0;
    }
    &.mce-active,
    &.mce-active:hover {
      border-radius: 2px;
      background: #FAFAFA;
      border-color: $gray-light;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, .08);
    }
  }

  .mce-ico {
    color: $text-color;
  }
}

.mce-floatpanel.mce-window {
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

// Primary Button
.mce-btn {
  &.mce-primary {
    background-color: $brand-success;
    &:hover {
      background-color: darken($brand-success, 10%);
    }
  }
}