#block-menu-block-2 {
  padding: 0px $padding-base-horizontal;
  margin-top: 15px;
  margin-bottom: 30px;
  select {
    @include select-lg();
    @include select-round();
    width: 100%; }

  @media #{$lg}, #{$md}, #{$sm} {
    display: none; } }
