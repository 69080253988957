#block-block-97 {
  ol {
    li {
      @media #{$lg} {
        &:nth-child(1) {
          @include graph-line-right();
          width: 430px;
          top: 185px;
          left: 45px; }
        &:nth-child(2) {
          @include graph-line-left();
          width: 430px;
          top: 129px;
          right: 71px; }
        &:nth-child(3) {
          @include graph-line-left();
          width: 400px;
          top: 264px;
          right: 43px; } } } } }
