div#fp-nav {
  ul {
    li,
    li:hover {
      width: auto;
      height: auto;
      margin: 5px 0px;
      background: transparent url('../img/scroll-dots.png') bottom center no-repeat;
      background-size: 4px 24px;
      padding-bottom: 30px;
      a {
        @include round();
        padding: 10px;
        border: 1px solid transparent;
        span {
          height: 12px;
          width: 12px;
          left: 4px;
          top: 4px;
          margin: 0px;
          background: rgba(255, 255, 255, 0.55); }
        &.active {
          border-color: $white;
          span {
            margin: 0px;
            background: $white; } }
        &:hover {
          span {
            background: $white; } } }
      &:last-child {
        background: none; } } } }
