@mixin inline-block() {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline; }

@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

@mixin round() {
  @include border-radius(1000px); }

@mixin transition($duration: 1s) {
  -webkit-transition: all $duration ease-in-out;
  -moz-transition: all $duration ease-in-out;
  -o-transition: all $duration ease-in-out;
  transition: all $duration ease-in-out; }

@mixin transition-none() {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none; }

@mixin background-size-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 50%; }

@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value; }

@mixin font-awesome() {
  font-family: "FontAwesome"; }

@mixin font-jdteck() {
  font-family: "jdteck" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin full-size() {
  width: calc(100% + 30px);
  position: relative;
  left: -15px; }

@mixin v-align-middle() {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%); }

@mixin select() {
  @include button-variant($brand-info, $white, $brand-primary);
  @include inline-block();
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background-image: url('../img/select-dropdown.png');
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: right 20px center;
  background-size: 14px 7px;
  padding: 8px 16px;
  padding-right: 50px;
  height: auto;
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
  vertical-align: center; }

@mixin select-lg() {
  background-position: right 30px center;
  padding: 15px 24px;
  padding-right: 70px; }
@mixin select-sm() {
  background-position: right 15px center;
  padding: 6px 12px;
  padding-right: 40px; }
@mixin select-xs() {
  font-size: $font-size-small;
  background-position: right 10px center;
  padding: 2px 8px;
  padding-right: 30px; }
@mixin select-round() {
  @include round(); }
