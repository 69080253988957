#region-slides {
  .block {
    @media #{$md},#{$lg} {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
    p {
      font-size: $font-size-h4;
      margin-bottom: 25px;
      @media #{$xs} {
        font-size: 18px; } }
    blockquote {
      p {
        font-size: $font-size-h3;
        margin-bottom: 0px; } }
    ul {
      margin-bottom: 35px;
      li {
        font-size: $font-size-h4;
        a, a:visited {
          font-size: $font-size-base; } } } } }
