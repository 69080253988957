#region-banner {
  position: relative;
  z-index: 5;

  .block {
    &.block-default-banner {
      padding-top: 150px;
      padding-bottom: 50px;
      text-align: center;
      background-color: $white;
      h1 {
        color: $brand-info;
        font-weight: bold; }
      @media #{$xs} {
        padding-top: 80px; } }
    p {
      font-size: $font-size-h4;
      margin-bottom: 25px;
      @media #{$xs} {
        font-size: 18px; } } } }
