.ui-autocomplete {
  position: absolute;
  top: 95px !important;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  _width: 160px;
  padding: 4px 0;
  margin: 2px 0 0 0;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid $brand-info;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent; }

  .ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: $text-color;
    white-space: nowrap;

    .apachesolr_autocomplete {
      &.suggestion {
        margin-right: 5px; }
      &.message {
        position: relative;
        top: 1px; } }

    &.ui-state-hover,
    &.ui-state-active,
    &.ui-state-focus {
      color: $white;
      text-decoration: none;
      background: $brand-primary;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border: 0px;
      margin: 0px;
      .apachesolr_autocomplete {
        &.message {
          color: opacify($white, 0.2); } } } } }

// White Version
body.white-nav {
  .ui-autocomplete:after {
    border-bottom-color: $white; } }
