// Main Site
#sb-site, .sb-site-container {
  background-color: white !important; }

// Sidebar
.sb-slidebar {
  ul.menu {
    margin: 0px;
    li {
      display: block;
      width: 100%;
      a {
        &,
        &:visited {
          padding: 15px;
          color: $white; }

        &:hover,
        &:focus {
          background-color: $gray-base;
          @include box-shadow(inset 5px 0px 6px rgba(0, 0, 0, 0.2)); }

        &.active {
          color: $brand-primary; } }

      &.active {
        a, a:visited {
          background-color: $brand-primary;
          color: $white;
          @include box-shadow(inset 5px 0px 6px rgba(0, 0, 0, 0.2)); } } } } }

// Toggle Icon
.sb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  background-image: none;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
  padding-top: 7px;

  // Hamburger
  .sb-toggle-hamburger {
    display: block;
    width: 30px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    cursor: pointer;
    -webkit-transition: .7s ease-in-out;
    -moz-transition: .7s ease-in-out;
    -o-transition: .7s ease-in-out;
    transition: .7s ease-in-out;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $brand-info;
      border-radius: 0px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out;

      &:nth-child(1) {
        top: 0px; }
      &:nth-child(2),
      &:nth-child(3) {
        top: 10px; }
      &:nth-child(4) {
        top: 20px; } }

    &.open span {
      &:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%; }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg); }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      &:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%; } } } }

body.white-nav {
  .sb-toggle {
    .sb-toggle-hamburger span {
      background: $white; } } }
