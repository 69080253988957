.view-blog {
  margin-top: 30px;
  @media #{$sm}, #{$xs} {
    margin-top: 15px; }
  .row {
    .background-cover {
      height: 400px;
      border: 1px solid #b1c3d1;
      margin-bottom: 30px;
      @media #{$sm}, #{$xs} {
        margin-bottom: 15px;
        height: 300px; }
      a {
        &, &:visited, &:focus, &:active, &:hover {
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          .overlay {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            @include gradient-vertical(transparent, darken(#707f8a, 15%));
            @include transition(0.3s);
            opacity: 0.8; }
          .content {
            padding: 25px;
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: 0;
            color: #FFF;
            .created {
              text-transform: uppercase;
              font-weight: bold; }
            .hr {
              background-color: $tealish;
              height: 1px;
              width: 150px;
              margin: 10px 0px; }
            .title {
              font-size: 31px;
              line-height: 1.3;
              font-weight: bold; } } }
        &:hover {
          .overlay {
            opacity: 1; } } } } } }
