//== Colors
//
//## Color an element based on the variables
.brand-primary {
  color: $brand-primary; }
.brand-success {
  color: $brand-success; }
.brand-info {
  color: $brand-info; }
.brand-warning {
  color: $brand-warning; }
.brand-danger {
  color: $brand-danger; }
.brand-teal {
  color: $teal; }
.brand-tealish {
  color: $tealish; }
.text-muted-more {
  color: #CCCCCC; }

//== Sizes
//
//## Set the size of a header from another header
.h1 {
  font-size: $font-size-h1; }
.h2 {
  font-size: $font-size-h2; }
.h3 {
  font-size: $font-size-h3; }
.h4 {
  font-size: $font-size-h4; }
.text-18 {
  font-size: 18px; }

//== Weight
//
//## Set the font weight
.weight-light {
  font-weight: 300; }
.weight-normal {
  font-weight: 400; }
.weight-medium {
  font-weight: 500; }
.weight-bold {
  font-weight: 700; }
.weight-ultra-bold {
  font-weight: 900; }
