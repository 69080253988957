$views-faq-width-lg: 385px;
$views-faq-width-md: 250px;
$views-faq-width-sm: 150px;
.view-faq {
  .view-filters {
    margin-top: 15px;
    margin-bottom: 50px;
    text-align: center;
    .views-exposed-widget {
      float: none;
      padding: 0px;
      &.views-widget-filter-title {
        width: 646px;
        margin: 0 auto;
        @media #{$xs} {
          width: 100%; }
        label {
          display: none; }
        .form-control {
          background: transparent url("../img/icon-search.png") right 15px center no-repeat;
          background-size: 23px 23px;
          border-width: 2px;
          height: 48px; } }
      &.views-submit-button {
        display: none; } } }

  .view-content {
    // Group Title
    > h3 {
      color: $tealish;
      padding-top: 30px;
      border-top: 2px solid $tealish;
      font-size: 31px;
      float: left;
      margin-top: 0px;
      @media #{$lg} {
        width: $views-faq-width-lg; }
      @media #{$md} {
        width: $views-faq-width-md; }
      @media #{$sm} {
        width: $views-faq-width-sm; }
      @media #{$xs} {
        margin-top: 30px;
        float: none;
        width: 100%; } }
    .views-row {
      padding-top: 10px;

      @media #{$lg} {
        margin-left: $views-faq-width-lg + 35px; }
      @media #{$md} {
        margin-left: $views-faq-width-md + 35px; }
      @media #{$sm} {
        margin-left: $views-faq-width-sm + 35px; }
      @media #{$xs} {
        margin-left: 0px; }
      &.views-row-last {
        margin-bottom: 80px; }
      .views-field-title {
        h3 {
          font-size: 31px;
          @media #{$xs} {
            font-size: 20px;
            line-height: 1.3; }
          a {
            color: $brand-info; } } }
      .views-field-field-answer {
        p {
          margin-bottom: 0px !important; } } } } }
