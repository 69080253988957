body {}

a {
  &, &:visited {}

  &:hover,
  &:focus {} }

p {
  color: #777777; }

h1 {
  margin-bottom: 25px;
  @media #{$sm} {
    font-size: 38px; }
  @media #{$xs} {
    font-size: 25px;
    line-height: 35px; }
  &.lined {
    &:after {
      margin-top: 15px;
      content: " ";
      display: block;
      background: $tealish;
      height: 1px;
      width: 200px; } } }

h2 {
  color: $brand-info;
  @media #{$sm} {}

  @media #{$xs} {} }

h3 {
  @media #{$sm} {}

  @media #{$xs} {} }

h4 {
  @media #{$sm} {}

  @media #{$xs} {} }

hr {
  border: 0px;
  background: none;
  margin-top: 11px;
  margin-bottom: 68px;
  &:after {
    color: $blue-light;
    content: "....................................";
    letter-spacing: 4px; } }


blockquote {
  font-weight: 300;
  font-style: italic;
  font-size: $font-size-h3;
  border: 0px;
  padding: 0px;
  padding-left: 25px;

  .image {
    margin-bottom: 25px;
    img {
      width: auto;
      height: 60px; } }

  p {
    color: $gray-light;
    margin-bottom: 11px !important;
    &:before,
    &:after {
      @include font-jdteck();
      color: $brand-info;
      position: relative;
      font-size: $font-size-base; }
    &:before {
      content: "\65";
      left: -25px;
      top: -10px;
      margin-right: -16px; }
    &:after {
      content: "\66";
      right: 5px;
      bottom: 10px;
      margin-left: 10px; } }
  small {
    margin-top: 5px;
    font-size: 18px;
    color: #a4abb6; }

  &.quotes-primary {
    p:before,
    p:after {
      color: $brand-primary; } }
  &.quotes-style2 {
    margin: 0px 66px;
    padding: 0px 37px;
    border-left: 1px solid $brand-info;
    margin-bottom: 35px;
    p {
      font-size: 30px;
      &:before {
        display: none; } }
    @media #{$xs} {
      margin-left: 0px;
      margin-right: 0px; } } }

select {
  &,
  &.form-control {
    @include select();
    &.select-lg {
      @include select-lg(); }
    &.select-sm {
      @include select-sm(); }
    &.select-xs {
      @include select-xs(); }
    &.select-round {
      @include select-round(); } } }

.grippie {
  display: none; }

.background-cover {
  @include background-size-cover(); }

.no-padding {
  padding: 0px !important;
  @media #{$xs} {
    padding-left: $padding-base-horizontal !important;
    padding-right: $padding-base-horizontal !important; } }

i.icon {
  @include inline-block();
  &.icon-flip-vertical {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); } }
