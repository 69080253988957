.node-type-case-study {
  .tabs--primary {
    @extend .container; }
  #region-content {
    > .container {
      margin: 0px;
      padding: 0px;
      width: 100%; } }

  .blocks {
    margin: 45px 0px;

    #carousel-photos {
      padding: 0px;
      margin: 0px;
      .carousel-indicators {
        bottom: 10px; } }

    .testimonial-block {
      text-align: center;
      position: relative;
      blockquote {
        @include v-align-middle();
        left: 40px;
        right: 40px;
        text-align: center;
        z-index: 2;
        margin-bottom: 0px;
        @media #{$sm}, #{$xs} {
          position: static;
          transform: none;
          padding: 50px 15px; }
        .image {
          @include inline-block();
          width: auto; } } } }

  .node-case-study {
    .col-icon {
      img {
        width: 91px;
        float: right;
        margin-top: 30px; } } } }
