#block-block-2 {
  @media #{$lg}, #{$md} {
    float: right;
    padding-right: 35px;
    position: relative; }
  @media #{$lg} {
    top: 45px;
    p {
      margin-bottom: 20px; } }
  @media #{$md} {
    p {
      font-size: $font-size-small;
      margin-bottom: 5px;
      margin-top: 15px; } } }
