.node-type-product {
  #region-banner {
    .block {
      @media #{$xs}, #{$sm} {
        padding-top: 73px;
        padding-bottom: 15px; } } } }
.node-product {
  // Adjust to correct column size based on design
  > .row {
    > .col-md-9 {
      width: 717px;
      @media #{$md} {
        width: 522px; }
      @media #{$xs}, #{$sm} {
        width: auto; } }
    > .col-md-3 {
      width: 205px;
      @media #{$xs}, #{$sm} {
        width: auto; } } }

  .field-title {
    * {
      margin-top: 10px;
      @media #{$xs}, #{$sm} {
        text-align: center;
        margin-bottom: 5px; } } }
  .field-node-subtitle {
    @media #{$xs}, #{$sm} {
      text-align: center; } }

  .field-name-body {
    ul, ol {
      @extend .list-checkmarks; } }


  .field-image {
    min-height: 200px;
    @media #{$xs}, #{$sm} {
      display: none; }
    img:first-child {
      @media #{$lg} {
        padding: 0px 150px; }
      @media #{$md} {
        padding: 0px 80px; } }
    .loader {
      @include transition($transition-speed-base);
      opacity: 0;
      z-index: 3;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      left: 0px;
      text-align: center;
      .loader-inner {
        @include inline-block();
        margin: 0 auto;
        > div {
          background-color: $brand-primary; } } } }

  .field-body {
    border-bottom: 1px solid $brand-primary;
    margin-bottom: 25px;
    padding-bottom: 25px; }

  .field-sku {
    margin-bottom: 25px;
    padding-bottom: 25px;
    @media #{$xs} {
      font-size: $font-size-small; }
    .row {
      border-bottom: 1px solid #EEE;
      padding-bottom: 5px;
      margin-bottom: 5px;
      .field-sku-value {
        &-sku {
          font-weight: 100; }
        &-desc {
          color: #777; } }
      &:last-child {
        border: 0px; } } }



  .field-contact-form {
    .block {
      .block-title {
        display: none; }
      form {
        textarea {
          height: 135px;
          margin-bottom: 25px; } }
      .tabbable {
        display: none; } } }

  .field-more-info {
    // border-top: 1px solid $brand-primary
    margin-top: 30px;
    // padding-top: 50px
    @media #{$sm} {
      margin-bottom: 20px; }
    @media #{$xs} {
      display: none; }
    table {
      font-size: 12px; } }

  #block-afb-2 {
    @media #{$xs}, #{$sm} {
      margin-bottom: 30px;
      .form-submit {
        width: 100%;
        text-align: left;
        .icon-arrow-right {
          float: right; } } } }

  .field-images-preview {
    margin-bottom: 20px;
    @media #{$xs}, #{$sm} {
      display: none; }
    .image-preview {
      a {
        &, &:visited, &:focus, &:active {
          @include size(175px, 175px);
          display: block;
          border: 1px solid #CCC;
          padding: 10px;
          text-align: center;
          position: relative;
          z-index: 0;
          margin-top: -1px;
          text-decoration: none; }
        &:hover {
          text-decoration: none; }
        &.active {
          border-color: $brand-primary;
          z-index: 2; }
        img {
          @include inline-block();
          width: 100%;
          height: auto; } }
      &:first-child {
        a {
          margin-top: 0px; } } } }

  .well-info {
    width: 100%; }

  .field-documents {
    @media #{$xs} {
      display: none; }
    .btn {
      width: 100%; } }

  .field-disclaimer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #CCC;
    font-size: 11px; } }
