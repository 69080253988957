.img-overlaid {
  position: relative;
  margin-bottom: 25px;
  img {
    position: relative;
    z-index: 1;
    width: 600px;
    height: auto; }
  > div {
    display: block;
    position: absolute;
    top: 20px;
    left: 440px;
    h2 {
      margin-top: 0px;
      position: relative;
      z-index: 2;
      padding: 30px;
      background: $white; }
    p {
      font-size: 18px;
      padding-left: 190px; } }


  @media #{$md} {
    > div {
      left: 240px;
      top: 50px;
      h2 {
        padding: 15px;
        margin-bottom: 0px; }
      p {
        position: relative;
        background: $white;
        padding: 15px;
        padding-top: 0px;
        z-index: 2; } } }

  @media #{$xs}, #{$sm} {
    img {
      @include full-size(); }
    > div {
      &, h2, p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        padding: 0; }
      h2 {
        margin-top: 22px; } } }

  @media #{$xs} {
    h2 {
      font-size: 25px;
      line-height: 35px; } } }
