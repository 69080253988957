.btn {
  &.btn-ghost {
    background-color: transparent;

    &:hover:not(.btn-link) {
      color: $white; }

    &.btn-default {
      color: $btn-default-color;
      border-color: $btn-default-border;
      &:hover {
        background-color: $btn-default-border; } }
    &.btn-primary {
      color: $brand-info;
      border-color: $brand-primary;
      &:hover {
        background-color: $brand-primary; } }
    &.btn-info {
      color: $btn-info-bg;
      border-color: $btn-info-border;
      &:hover {
        background-color: $btn-info-bg; } }
    &.btn-success {
      color: $btn-success-bg;
      border-color: $btn-success-border;
      &:hover {
        background-color: $btn-success-bg; } }
    &.btn-warning {
      color: darken($brand-warning, 50);
      border-color: $btn-warning-border;
      &:hover {
        color: darken($brand-warning, 50);
        background-color: $btn-warning-bg; } }
    &.btn-danger {
      color: $btn-danger-bg;
      border-color: $btn-danger-border;
      &:hover {
        background-color: $btn-danger-bg; } } }

  &.btn-text-white {
    color: white !important; } }
