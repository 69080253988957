.modal {
  &-no-padding {
    .modal-content {
      @include border-radius(0px);
      overflow: hidden;
      border: 0px;
      .modal-body {
        overflow: hidden;
        padding: 0px;
        video {
          margin: 0 0 -10px 0 !important; } } } } }
