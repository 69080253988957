.btn {
  & {
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;

    .icon-arrow-right {
      margin-left: 50px;
      position: relative;
      &:before {
        position: absolute;
        top: 2px;
        right: -3px;
        font-size: 18px; } } }



  &.btn-lg {
    letter-spacing: 1px;
    .icon-arrow-right {
      margin-left: 70px;
      &:before {
        top: -2px;
        right: -5px;
        font-size: 22px; } } }
  &.btn-sm {
    .icon-arrow-right {
      margin-left: 30px;
      &:before {
        top: 3px;
        right: 0px;
        font-size: 16px; } } }
  &.btn-xs {
    font-weight: normal;
    .icon-arrow-right {
      margin-left: 25px;
      &:before {
        top: 3px;
        right: 0px;
        font-size: 16px; } } }

  &.btn-warning {
    color: darken($brand-warning, 50); }

  &.btn-icon-only {
    text-align: center;
    padding: 0px 25px;
    .icon-arrow-right {
      margin: 0px;
      &:before {
        position: relative;
        top: 2px;
        right: 0px;
        left: 0px; } } } }
