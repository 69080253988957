#region-navigation {
  position: absolute;
  left: 0;
  right: 0;
  top: 17px;
  z-index: 10;
  .navbar {
    border: 0px;
    min-height: inherit;
    margin-bottom: 0px;
    position: relative;

    .col-logo,
    .col-menu,
    .col-search {
      position: absolute; }

    .col-logo {
      left: 0px;
      width: 158px;
      z-index: 2; }
    .col-menu {
      left: 158px;
      right: 0px;
      z-index: 3;
      @media #{$lg} {
        left: 205px;
        right: 205px; }
      .navbar-collapse {
        padding: 0px; } }

    .col-search {
      width: 350px;
      right: 0;
      display: none;
      z-index: 2;
      @media #{$lg} {
        display: block; } }

    .logo-container {
      position: relative;

      .logo {
        position: absolute;
        z-index: 1;
        &, &:hover {
          @include inline-block();
          text-decoration: none;
          position: relative; }

        @media #{$xs} {
          top: 8px; }

        &, object {
          @include size(158px, 37px);
          @media #{$xs} {
            @include size(100px, 24px); }

          &.logo-white {
            opacity: 0; } }

        object {
          @include transition($transition-speed-normal);
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none; } }
      .logo-overlay {
        @include size(158px, 37px);
        @media #{$xs} {
          @include size(100px, 24px); }
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        z-index: 2; } } } }

// White nav version
body.white-nav {
  #region-navigation {
    .logo {
      object {
        &.logo-default {
          opacity: 0 !important; }
        &.logo-white {
          opacity: 1 !important; } } } } }
