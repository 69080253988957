#region-footer {
  @media #{$xs}, #{$sm} {
    padding-top: 50px;
    padding-bottom: 0px; }

  .region-footer-left,
  .region-footer-right,
  .region-footer-bottom {
    position: absolute;
    @media #{$xs}, #{$sm} {
      position: static; } }

  .region-footer-left {
    left: 35px;
    top: 0px;

    .logo {
      &, &:hover {
        position: relative;
        top: 63px;
        @include inline-block();
        text-decoration: none;
        @media #{$xs}, #{$sm} {
          top: 0px; } }

      &, object {
        @include size(158px, 37px); }
      object {
        pointer-events: none; } }
    @media #{$xs}, #{$sm} {
      text-align: center;
      .col-menu {
        display: none; } } }
  .region-footer-right {
    right: 35px;
    top: 0px; }
  .region-footer-bottom {
    padding: 0px 30px;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: #07212c;
    height: 32px;
    line-height: 32px;
    font-size: $font-size-small;
    color: $tealish;
    a, a:visited, a:focus, a:active {
      color: $teal; }
    @media #{$xs}, #{$sm} {
      height: auto;
      margin-top: 15px;
      > .row > div {
        text-align: center; } } } }
