.container-padded-xs {
  @media #{$xs}, #{$sm} {
    padding: 0px $padding-content-horizontal; } }

.container-full {
  padding: 0px 35px;
  @media #{$xs}, #{$sm} {
    padding: 0px $padding-content-horizontal; } }

.content-padded {
  @media #{$lg} {
    padding-left: 250px;
    padding-right: 250px; }
  @media #{$md} {
    padding-left: 150px;
    padding-right: 150px; }
  @media #{$sm} {
    padding-left: 50px;
    padding-right: 50px; }
  @media #{$xs} {
    padding-left: 0px;
    padding-right: 0px; } }

.content-padded-less {
  @media #{$lg} {
    padding-left: 15%;
    padding-right: 15%; }
  @media #{$sm} {
    padding-left: 5%;
    padding-right: 5%; }
  @media #{$xs} {
    padding-left: 0px;
    padding-right: 0px; } }

.content-padded-left {
  padding-left: 20%;
  @media #{$sm} {
    padding-left: 5%; }
  @media #{$xs} {
    padding-left: 0%; } }

.content-padded-right {
  padding-right: 20%;
  @media #{$sm} {
    padding-right: 5%; }
  @media #{$xs} {
    padding-right: 0%; } }

.block-layout-contained_left,
.block-layout-contained_right {
  @media #{$xs} {
    .block-content-container {
      padding: 0px 30px !important; } } }
