#block-taxonomy-menu-block-3 {
  select {
    @include border-radius(0px);
    background-color: $brand-info;
    color: $white;
    width: 100%;
    border: 0px;
    text-align: left;
    color: $blue-light;
    padding-top: 13px;
    padding-bottom: 13px;
    text-transform: none;
    background-image: url('../img/select-dropdown-light.png'); } }
