@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }


.circle-animated {
  margin: 45px 0px;
  background: transparent url('../img/circle-bg.png') center center no-repeat;
  background-size: 824px 342px;
  @media #{$sm} {
    background-size: 720px auto; }
  @media #{$xs} {
    display: none; }
  .circle {
    margin: 0 auto;
    position: relative;
    &, img {
      @include size(420px, 420px);
      @media #{$sm} {
        @include size(360px, 360px); } }
    img {
      -o-animation-play-state: running;
      -moz-animation-play-state: running;
      -webkit-animation-play-state: running;
      animation: spin 4s linear 0s infinite reverse;
      -moz-animation: 4s linear 0s reverse none infinite spin;
      -webkit-animation: spin 4s linear 0s infinite reverse;
      -o-animation: spin 4s linear 0s infinite reverse;
      position: absolute; } } }

