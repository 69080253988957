#block-taxonomy-menu-block-1 {
  background-color: $brand-info;
  .block-content {
    ul {
      margin: 0px;
      padding: 0px;
      text-align: center;
      li {
        @include inline-block();
        list-style: none;
        a {
          &, &:visited, &:focus {
            display: block;
            height: 44px;
            line-height: 44px;
            color: #b3d5ee;
            text-transform: uppercase;
            font-size: $font-size-small;
            font-weight: bold;
            padding: 0px 23px;
            letter-spacing: 1px;
            text-decoration: none;
            border-radius: 0px;
            @media #{$md} {
              padding: 0px 7px; } }
          &:hover {
            text-decoration: none;
            background-color: $brand-primary;
            color: $white; } }
        &.active,
        &.active-trail {
          a {
            &, &:visited, &:focus {
              color: $white; }
            &:hover {} } } } } } }
