#block-block-80 {
  ol {
    li {
      &:nth-child(1) {
        @include graph-line-right();
        width: 430px;
        top: 185px;
        left: 15px; }
      &:nth-child(2) {
        @include graph-line-left();
        width: 430px;
        top: 118px;
        right: 63px; }
      &:nth-child(3) {
        @include graph-line-left();
        width: 400px;
        top: 243px;
        right: 8px; } } } }
