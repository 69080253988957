.btn {
  &.btn-white {
    &:not(.btn-link) {
      background-color: white !important;
      border-color: white !important; }

    &.btn-default {
      &, &:hover {
        color: $text-color; } }
    &.btn-primary {
      &, &:hover {
        color: $brand-primary; } }
    &.btn-info {
      color: $brand-info;
      &:hover {
        color: $brand-primary; } }
    &.btn-success {
      &, &:hover {
        color: $brand-success; } }
    &.btn-warning {
      &, &:hover {
        color: darken($brand-warning, 50); } }
    &.btn-danger {
      &, &:hover {
        color: $brand-danger; } } } }
