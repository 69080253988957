.table-steps {
  width: 725px;
  margin: 0 auto;
  @media #{$sm}, #{$xs} {
    width: 100%; }
  tr {
    td {
      position: relative;
      padding-bottom: 30px;
      &:first-child {
        width: 325px;
        @media #{$xs} {
          width: 100px; } }
      img {
        width: 280px;
        padding: 4px;
        border: 1px solid $brand-primary;
        @media #{$xs} {
          display: none; } }
      .label {
        position: absolute;
        left: -100px;
        top: 55px;
        @media #{$sm} {
          left: 40px; }
        @media #{$xs} {
          position: static;
          padding: 5px 15px;
          font-size: $font-size-h4; } }
      h2 {
        margin: 0px; } } } }
