// Media query variables.
$xs: "screen and (max-width: #{$screen-xs-max})";
$sm: "screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})";
$md: "screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})";
$lg: "screen and (min-width: #{$screen-lg-min})";

// Colors
$white: #FFFFFF;
$black: #000000;
$blue-light: #D0DCE2;
$teal: #76b7c6;
$tealish: #6897b9;

// Header Multipliers
$headers-sm-multiplier: 0.9;
$headers-xs-multiplier: 0.8;

// Padding
$padding-content-horizontal: 15px;

// Footer
$footer-height: 50px;

// Animation Speed
$transition-speed-base: 0.3s;
$transition-speed-normal: 0.7s;
$transition-speed-slow: 1s;

// Labels
$label-transparent-multiplier: 0.14;
