.nav-tabs {
  > li {
    &,
    &.active {
      > a {
        &, &:focus, &:active, &:hover {
          color: $brand-info;
          font-weight: bold;
          //border-top-right-radius: $border-radius-large
 } } }          //border-top-left-radius: $border-radius-large
    > a:hover {
      border-color: $brand-info;
      background-color: $brand-info;
      color: $white; } } }
.tab-content {
  &-bordered {
    border: 1px solid #DDD;
    padding: 20px;
    border-top: 0px; } }
