div.contextual-links-wrapper {
  a.contextual-links-trigger {
    @include border-radius(0px);
    background: transparent url('../img/contextual-links-trigger.png') center center no-repeat;
    background-size: 23px 12px;
    width: 32px;
    height: 24px;
    border: 0px;
    &:hover {
      width: 32px;
      @include border-radius(0px);
      border: 0px;
      background-image: url('../img/contextual-links-trigger-hover.png');
      background-color: transparent; } }
  &.contextual-links-active {
    a.contextual-links-trigger {
      border: 0px;
      width: 32px;
      background-color: transparent;
      background-image: url('../img/contextual-links-trigger-hover.png');
      background-position: center center; } }

  ul.contextual-links {
    top: 24px;
    z-index: 3;
    padding: 5px 0px;
    border: 0px;
    @include border-radius($border-radius-small);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    li {
      a {
        font-family: $font-family-base;
        font-size: $font-size-base;
        margin: 0px;
        padding: 8px 15px; }
      a:hover {
        background-color: $brand-primary;
        color: $white !important; } } } }

// Place the contextual links and quickedit buttons to the bottom
// of these regions
.region-banner {
  // Move Contextual Links to the bottom right of the block
  div.contextual-links-wrapper {
    bottom: 5px;
    right: 5px;
    left: auto;
    top: auto; }

  // Move Quick Edit Links to the bottom right of the block
  .quickedit-links,
  .dz-uploader {
    top: auto;
    bottom: 10px;
    z-index: 3; } }
