#region-content {
  .region-page-content {
    padding: 30px 0px;
    @media #{$xs} {
      padding-top: 0px;
      padding-bottom: 0px; }
    p {
      margin-bottom: 36px; }
    p, ul, ol, table td {
      color: #777777; } } }
