.page-node-123 {

  #region-content .region-page-content p {
    margin-bottom: 11px; }

  .color-block {
    @include inline-block;
    @include size(130px, 150px);
    position: relative;
    span {
      position: absolute;
      bottom: 5px;
      left: 0px;
      right: 0px;
      color: $white;
      text-align: center;
      font-size: $font-size-small; }

    &.background-primary {
      background-color: $brand-primary; }
    &.background-success {
      background-color: $brand-success; }
    &.background-info {
      background-color: $brand-info; }
    &.background-warning {
      background-color: $brand-warning; }
    &.background-danger {
      background-color: $brand-danger; }
    &.background-teal {
      background-color: $teal; } }

  .font-example {
    &-lato {
      font-family: "Lato" !important; }
    &-roboto {
      font-family: "Roboto" !important; }

    .font-example-heading {
      span {
        font-size: 100px; } }
    .font-example-name {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $gray-lighter; }
    .font-example-alphabet {
      color: $gray-light;
      margin-bottom: 40px; } }
  .section-icons {
    .icon {
      font-size: 60px; } } }
