@mixin btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }
}

.elfinder {
  min-height: 100px;
  border: 0px;

  .ui-widget-header {
    border: 0px;
    background: none;
  }

  .ui-state-default {
    border: none !important;
    background: none;
  }

  .elfinder-cwd-wrapper {
    padding: 0px;
    border: 2px dashed transparent;
    &.elfinder-droppable-active {
      border: 2px dashed $brand-success;
      &:after {
        content: "Drop File";
        position: absolute;
        bottom: 0px;
        right: 0;
        text-align: center;
        color: $brand-success;
        background-color: white;
        padding: 5px 10px;
      }
    }
  }

  .elfinder-cwd-file {
    position: relative;
    box-shadow: inset 0 0 15px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .05);
    background: #EEE !important;
    border: 1px solid #D7D7D7 !important;
    border-radius: 0px;
    width: 136px;
    height: 136px;
    margin: 10px !important;
    position: relative;

    &.ui-selecting,
    &.ui-selected {
      border-color: $brand-primary !important;
    }

    &, * {
      outline: none;
    }

    .elfinder-cwd-file-wrapper {
      bottom: 21px;
      &,
      .elfinder-cwd-icon {
        border-radius: 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: auto;
        height: auto;
        padding: 0px;
        margin: 0px;
      }

      .elfinder-cwd-icon {
        bottom: 0px;
        width: auto;
        height: auto;
        background: none;
        cursor: pointer;
        &:before {
          font-family: "FontAwesome";
          content: "\f016";
          font-size: 60px;
          color: #888;
          position: relative;
          top: 22px;
        }

        &.elfinder-cwd-icon-directory {
          &:before {
            content: '\f114';
          }
          &:hover {
            &:before {
              //color: #D0D0D0;
            }
          }
        }

        &.elfinder-cwd-icon-pdf {
          &:before {
            content: "\f1c1";
          }
        }

        &.elfinder-cwd-icon-application:before {
          content: "\f1c9";
        }
        &.elfinder-cwd-icon-text:before {
          content: "\f0f6";
        }
        &.elfinder-cwd-icon-image:before {
          content: "\f1c9";
        }
        &.elfinder-cwd-icon-audio {
          content: "\f1c7";
        }
        &.elfinder-cwd-icon-rtf:before {
          content: "\f0f6";
        }
        &.elfinder-cwd-icon-rtfd:before {
          content: "\f0f6";
        }
        &.elfinder-cwd-icon-pdf:before {
          content: "\f1c1";
        }
        &.elfinder-cwd-icon-ms-excel:before {
          content: "\f1c3";
        }
        &.elfinder-cwd-icon-msword:before {
          content: "\f1c2";
        }
        &.elfinder-cwd-icon-html:before {
          content: "\f1c9";
        }

        &.elfinder-cwd-icon-video:before,
        &.elfinder-cwd-icon-flash-video:before {
          content: "\f1c8";
        }

        &.elfinder-cwd-icon-css:before,
        &.elfinder-cwd-icon-javascript:before,
        &.elfinder-cwd-icon-x-javascript:before,
        &.elfinder-cwd-icon-x-perl:before,
        &.elfinder-cwd-icon-x-python:before,
        &.elfinder-cwd-icon-x-ruby:before,
        &.elfinder-cwd-icon-x-sh:before,
        &.elfinder-cwd-icon-x-shellscript:before,
        &.elfinder-cwd-icon-x-c:before,
        &.elfinder-cwd-icon-x-csrc:before,
        &.elfinder-cwd-icon-x-chdr:before,
        &.elfinder-cwd-icon-x-c--:before,
        &.elfinder-cwd-icon-x-c--src:before,
        &.elfinder-cwd-icon-x-c--hdr:before,
        &.elfinder-cwd-icon-x-java:before,
        &.elfinder-cwd-icon-x-java-source:before,
        &.elfinder-cwd-icon-x-php:before,
        &.elfinder-cwd-icon-xml:before {
          content: "\f1c9";
        }

        &.elfinder-cwd-icon-zip:before,
        &.elfinder-cwd-icon-x-zip:before,
        &.elfinder-cwd-icon-x-7z-compressed:before,
        &.elfinder-cwd-icon-x-gzip:before,
        &.elfinder-cwd-icon-x-tar:before,
        &.elfinder-cwd-icon-x-bzip:before,
        &.elfinder-cwd-icon-x-bzip2:before,
        &.elfinder-cwd-icon-x-rar:before,
        &.elfinder-cwd-icon-x-rar-compressed:before {
          content: "\f1c6";
        }

        &.elfinder-cwd-icon-group:before {
          content: "\f0c5";
        }

        &.elfinder-cwd-icon-image {
          &:before {
            content: "";
          }
        }
      }
    }

    .elfinder-cwd-filename {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      max-height: 100%;
      word-wrap: normal;
      text-align: center;
      font-weight: 700;
      background: rgba(255, 255, 255, .9) !important;
      border-radius: 0px;
      padding: 5px;
      margin: 0px;
      border-top: 1px solid #DDD;
      font-size: 10px;
      cursor: pointer;
    }
  }

  .elfinder-statusbar {
    display: none !important;
  }

  /**
   * elFinder Toolbar
   *
   *
   */
  .elfinder-toolbar {
    border-radius: 0px;
    border: 0px;
    .elfinder-buttonset {
      border: none;
      margin: 0px;
      .elfinder-button {
        width: auto;
        height: auto;
        padding: 0px;
        position: relative;
        cursor: pointer;

        form {
          cursor: pointer;
          display: block;
          left: 0;
          bottom: 0;
          input {
            cursor: pointer;
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }
        }

        .elfinder-button-icon {
          display: none;
          cursor: pointer;
          &.elfinder-button-icon-upload {
            margin-left: 10px;
            display: inline-block;
            background: none;
            font-weight: 500;
            font-size: 15px;
            font-family: Roboto, sans-serif;
            background: $brand-primary;
            color: #FFFFFF;
            padding: 3px 10px 2px;
            border-radius: 3px;
            white-space: nowrap;
            outline: none;
            text-decoration: none;
            z-index: 1;
            box-sizing: inherit;
            transform: translateZ(0);
            cursor: pointer;
            display: inline-block;
            overflow: hidden;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            width: 90px;
            height: 30px;
            line-height: 30px;
            text-align: center;

            &:before {
              font-family: "FontAwesome";
              content: "\f0ee";
            }
            &:after {
              padding-left: 10px;
              content: "Add File";
            }
          }

          &.elfinder-button-icon-up,
          &.elfinder-button-icon-mkdir {
            margin-left: 10px;
            display: inline-block;
            background: none;
            font-weight: 500;
            font-size: 15px;
            font-family: Roboto, sans-serif;
            background: #BBBBBB;
            color: #fff;
            padding: 3px 10px 2px;
            border-radius: 3px;
            white-space: nowrap;
            outline: none;
            text-decoration: none;
            z-index: 1;
            box-sizing: inherit;
            transform: translateZ(0);
            cursor: pointer;
            display: inline-block;
            overflow: hidden;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
          }

          &.elfinder-button-icon-up {
            background: $brand-success;
            &:before {
              font-family: "FontAwesome";
              content: "\f062";
            }
          }
          &.elfinder-button-icon-mkdir {
            background: $brand-primary;
            width: 117px;
            &:before {
              font-family: "FontAwesome";
              content: '\f067';
              padding-right: 5px;
            }
            &:after {
              content: "New Folder";
            }
          }
        }
      }
    }
  }
  // End Toolbar

  .ui-resizable-handle {
    display: none !important;
  }

  .elfinder-droppable-active .elfinder-cwd-icon-directory:before {
    content: '\f07c';
  }

  //.elfinder-cwd-view-icons .elfinder-cwd-file-wrapper,
  //.elfinder-cwd-icon {
  //  width: 100px;
  //  height: 100px;
  //}
  //
  //.elfinder-cwd-view-icons .elfinder-cwd-file {
  //  margin: 0 20px 40px 0;
  //  width: 120px;
  //  height: 120px;
  //}

  .ui-widget-content {
    border: none !important;
  }

  .elfinder-dialog {
    border: none;
    background: transparent;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);

    .ui-dialog-titlebar {
      background-color: white;
      padding: 10px 15px;
      .ui-icon.ui-icon-closethick {
        display: none;
      }
    }

    .ui-dialog-content {
      box-shadow: none;
      border: 0px;
      border-radius: 0px;
      padding: 15px;

      .elfinder-dialog-icon {
        position: static;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 0px;
      }
    }
    .ui-button {
      @extend .btn-sm;
      @extend .btn-default;
    }
    #elfinder-fm-file-desc-btn-save {
      @include btn();
      @extend .btn-sm;
      @extend .btn-success;
      display: block;
      margin: 10px 0px;
    }
    #elfinder-fm-file-desc {
      @extend .form-control;
      border: 2px solid $input-border !important;
    }
  }

  &,
  .elfinder-workzone,
  .elfinder-cwd-wrapper {
    height: 100% !important;
  }

  .elfinder-navbar {
    display: none !important;
  }

  .elfinder-toolbar {
    background: none;
  }
  .elfinder-navbar {
    padding: 5px;
  }
  .ui-state-default {
    background: none;
    border: 1px solid transparent;
  }
  .elfinder-cwd-view-icons {
    .elfinder-cwd-file {
      border: 1px solid transparent;
    }
  }
}

// End elfinder

// ElFinder Right Click Menu
.elfinder-contextmenu,
.elfinder-contextmenu-sub {
  background: white !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border: none;
  .elfinder-contextmenu-item {
    &:hover {
      background-color: #2C3E50;
      color: white;
    }

    .elfinder-button-icon {
      background: none;
      &:before {
        font-family: "FontAwesome";
      }
      &.elfinder-button-icon {
        &-open:before {
          content: "\f07c";
        }
        &-quicklook:before {
          content: "\f06e";
        }
        &-copy:before {
          content: "\f0c5";
        }
        &-cut:before {
          content: "\f0c4";
        }
        &-duplicate:before {
          content: "\f24d";
        }
        &-rm:before {
          content: "\f1f8";
        }
        &-rename:before {
          content: "\f033";
        }
        &-archive:before {
          content: "\f1c6";
        }
        &-extract:before {
          content: "\f00a";
        }
        &-info:before {
          content: "\f05a";
        }
        &-download:before {
          content: "\f0ed";
        }
        &-resize:before {
          content: "\f0b2";
        }
        &-getfile:before {
          content: "\f245";
        }
        &-reload:before {
          content: "\f021";
        }
        &-back:before {
          content: "\f060";
        }
        &-upload:before {
          content: "\f0ee";
        }
        &-mkdir:before {
          content: "\f07b";
        }
        &-mkfile:before {
          content: "\f15c";
        }
        &-sort:before {
          content: "\f15d";
        }
        &-paste:before {
          content: "\f0ea";
        }
        &-edit:before {
          content: "\f040";
        }
      }
    }
  }
}
// End ElFinder Right Click Menu

// Image Quicklook
.elfinder-quicklook {
  background: white;
  @include box-shadow(0px 0px 10px rgba(0,0,0,0.2));
  border-radius: $border-radius-base;
  .elfinder-quicklook-titlebar {
    background: white;
  }
  .elfinder-quicklook-navbar {
    display: none;
  }
}

// Standalone Window
body.elfinder {
  background-color: white;
  .elfinder-statusbar {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    padding: 5px;
    background-color: #EFEFEF !important;
    display: block;
    z-index: 1;
    color: #444 !important;
  }
}

.elfinder-button-search {
  input {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    padding: 3px 20px;
  }
}

.elfinder .ui-state-hover, .elfinder .ui-widget-content .ui-state-hover, .elfinder .ui-widget-header .ui-state-hover, .elfinder .ui-state-focus, .elfinder .ui-widget-content .ui-state-focus, .elfinder .ui-widget-header .ui-state-focus {
  background: transparent;
}

.elfinder-toolbar-button-separator {
  display: none;
}

.elfinder-ltr {
  .elfinder-button-search {
    display: none !important;
  }
  .elfinder-button-search.ui-widget-content {
    border: 0px;
  }
}

.elfinder-rtl {
  .elfinder-button-search {
    .ui-icon-search {
      right: 5px;
    }
  }
}