#block-block-87 {
  ol {
    li {
      @media #{$lg} {
        &:nth-child(1) {
          @include graph-line-right();
          width: 430px;
          top: 229px;
          right: 600px; }
        &:nth-child(2) {
          @include graph-line-left();
          width: 430px;
          top: 109px;
          right: 94px; }
        &:nth-child(3) {
          @include graph-line-left();
          width: 400px;
          top: 304px;
          right: 109px; } } } } }
