.node-blog-article {
  .blog-header {
    border-bottom: 1px solid $brand-primary;
    padding-bottom: 7px;
    .created {
      text-transform: uppercase;
      color: #255175;
      font-weight: bold; }
    .share-link {
      a {
        &, &:focus, &:active, &:visited {
          text-decoration: none;
          font-weight: bold;
          color: $tealish;
          .icon {
            margin-left: 5px;
            font-size: 24px;
            position: relative;
            top: -4px; } }
        &:hover {
          color: darken($tealish, 15%);
          text-decoration: none; } } } }
  .blog-picture {
    border: 1px solid #b1c3d1; }
  .popover-content {
    width: 280px; } }
