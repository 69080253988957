#block-block-111 {
  ol {
    li {
      @media #{$lg} {
        &:nth-child(1) {
          @include graph-line-right();
          width: 430px;
          top: 175px;
          left: 79px; }
        &:nth-child(2) {
          @include graph-line-left();
          width: 430px;
          top: 209px;
          right: 144px; }
        &:nth-child(3) {
          @include graph-line-left();
          width: 400px;
          top: 364px;
          right: 93px; } } } } }
