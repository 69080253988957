.block-bean-read-more {

  .row {
    margin-bottom: 35px;
    > div {
      text-align: center; } }

  .read-more-block {
    position: relative;
    text-align: left;
    padding-bottom: 40px;
    .overlay {
      position: absolute;
      height: 70px;
      width: 100%;
      bottom: 0px;
      left: 0;
      right: 0;
      z-index: 3;
      opacity: 1;
      @include transition(0.3s);
      @include gradient-vertical(transparent, #FFF); } }

  .btn {
    margin: 0 auto;
    width: auto !important;
    display: inline-block !important;
    padding-left: 30px;
    padding-right: 30px; } }
