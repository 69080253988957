#block-search-form {
  text-align: right;
  .input-group {
    height: 40px;
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    input {
      @include round();
      @include inline-block();
      @include transition($transition-speed-base);
      @include placeholder(rgba(0, 0, 0, 0.5));
      float: none;
      background: transparent url('../img/icon-search.png') left 10px center no-repeat;
      background-size: 23px 23px;
      border-color: #c3d1db;
      padding-left: 45px;
      width: 175px;
      &.throbbing {
        background-image: url('../img/icon-search.png'), url(/misc/throbber-active.gif);
        background-color: transparent;
        background-position: left 10px center, right 10px center;
        background-size: 23px 23px, 15px 13px;
        background-repeat: no-repeat, no-repeat; }
      &:focus {
        background-color: $white;
        border-color: $brand-info;
        outline: none;
        width: 280px; } } }

  .input-group-btn,
  .form-actions {
    display: none; } }

// White Version
body.white-nav {
  #block-search-form {
    .input-group {
      input {
        border-color: #576a77;
        background-image: url('../img/icon-search-white.png');
        color: $white;
        @include placeholder($white);
        &:focus {
          box-shadow: none;
          background-image: url('../img/icon-search.png');
          @include placeholder($gray-light);
          color: $text-color; } }
      &.throbbing {
        background-image: url('../img/icon-search-white.png'), url(/misc/throbber-active.gif); } } } }
