.well {
  @include border-radius($border-radius-large);
  @include box-shadow(none);
  background-color: $brand-primary;
  border: 0px;
  color: $white;

  &-info {
    @include inline-block();
    width: auto;
    padding: 15px;
    text-align: center;
    .title {
      margin-top: 5px; }
    hr {
      margin: 8px 10px;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.2);
      &:after {
        display: none; } }
    .subtitle {
      color: rgba(0, 0, 0, 0.45);
      font-weight: bold; }
    img {
      display: block;
      margin: 0 auto; }
    .btn {
      margin-top: 10px; }
    @media #{$xs} {
      width: calc(100% + 30px) !important;
      position: relative;
      left: -15px;
      border-radius: 0px;
      position: relative;
      padding-top: 40px;
      padding-bottom: 40px;
      margin-bottom: 0px;
      img {
        position: absolute;
        top: 25px; }
      .title,
      .subtitle,
      hr {
        margin-left: 100px; }
      hr {
        margin-left: 120px; }
      .btn {
        margin-top: 35px; } }
    &-lg {
      display: block;
      width: 100%;
      padding: 35px 25px;
      position: relative;
      .title,
      hr,
      .subtitle {
        font-size: 31px;
        margin-left: 120px;
        font-weight: bold;
        color: $white;
        text-align: left;
        line-height: 1.1;
        @media #{$md} {
          margin-left: 0px; } }
      .title,
      .subtitle {
        margin-right: 15px; }
      img {
        width: 120px !important;
        height: auto !important;
        position: absolute;
        top: 20px;
        left: 15px;
        @media #{$md} {
          display: none; } } } } }
