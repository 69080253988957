#block-block-45 {

  object {
    position: absolute;
    right: 0px;
    top: -60px;
    width: 740px;
    height: 600px;
    overflow: hidden; }

  @media #{$lg} {
    h1 {
      margin-top: 0px; }
    table {
      width: 430px;
      margin-top: 30px;
      td {
        padding-bottom: 0px; } } }

  @media #{$md} {
    .block-content-container {
      padding-right: 100px;
      h1 {
        font-size: $font-size-h2 {
         margin-top: 0px; } }
      table {
        td:last-child p {
          margin-bottom: 0px; } } } }


  @media #{$sm}, #{$xs} {
    text-align: center;
    .block-content-container {
      padding: 0px; }
    table {
      text-align: left;
      margin-bottom: 15px;
      p {
        margin-left: 10px; } } } }
