$editor-color: $brand-primary;
$editor-size: 50px;

.block {
  .block-quickedit-links {
    .btn-sm {
      padding: 0px 5px; } } }

.medium-editor-toolbar {
  background-color: $editor-color;

  &.medium-toolbar-arrow-under:after {
    top: $editor-size;
    border-color: $editor-color transparent transparent transparent; }

  &.medium-toolbar-arrow-over:before {
    top: -8px;
    border-color: transparent transparent $editor-color transparent; }

  li {
    padding: 0;
    button {
      min-width: $editor-size;
      height: $editor-size;
      border: none;
      border-right: 1px solid darken($editor-color, 5%);
      background-color: transparent;
      color: #fff;
      -webkit-transition: background-color 0.2s ease-in, color 0.2s ease-in;
      transition: background-color 0.2s ease-in, color 0.2s ease-in;
      &:hover {
        background-color: darken($editor-color, 15%);
        color: #fff; } }

    .medium-editor-button-active {
      background-color: darken($editor-color, 20%);
      color: #fff; }

    .medium-editor-button-last {
      border-right: none; } }

  .medium-editor-toolbar-form {
    .medium-editor-toolbar-input {
      height: $editor-size;
      background: $editor-color;

      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: #fff;
        color: rgba(255, 255, 255, 0.8); } }

    a {
      color: #fff; } } }

.medium-editor-toolbar-anchor-preview {
  background: $editor-color;
  color: #fff; }

.medium-editor-placeholder:after {
  color: #fff; }
