#block-formblock-contact-request {
  label,
  .vertical-tabs {
    display: none !important; }

  .form-control {
    &::-webkit-input-placeholder {
      font-style: italic; } }

  select {
    width: 100%;
    border: 1px solid #ccc;
    color: #999999;
    font-weight: normal;
    text-transform: none;
    &.valid,
    &.error {
      background: {
        size: 20px 20px;
        position: center right 10px; } }
    &.error {
      border-color: #cd3d34; }
    &.valid {
      border-color: #5cb85c; } } }
